$arrowBorderSize: 12px;
$popoverWidth: 300px;

@mixin downArrow() {
  content: ' ';
  border-top: $arrowBorderSize solid var(--content-area-background-color-hex);
  border-left: $arrowBorderSize solid transparent;
  border-right: $arrowBorderSize solid transparent;
  position: relative;
  top: calc($arrowBorderSize - 2px);
}

.popoverContainer {
  filter: drop-shadow(0 8px 12px rgb(28 31 58 / 50%));
  box-shadow: none !important; // NOTE: This seems to be necesssary in higher level envs, likely due to our our weird CSS issue.
  z-index: var(--above-top-z-index) !important;
  max-width: $popoverWidth;
}

.playerPositioning {
  bottom: 45px;

  @media (--min-width-small) {
    bottom: 67px;
  }

  @media (--min-width-medium) {
    bottom: 78px;
  }

  &::after {
    @include downArrow;

    left: 191px;

    @media (--min-width-small) {
      left: 179px;
    }

    @media (--min-width-medium) {
      left: 203px;
    }
  }
}

.nowPlayingPositioning {
  &::after {
    @include downArrow;

    left: calc(($popoverWidth - ($arrowBorderSize * 2)) / 2);
  }
}

.popoverContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: var(--font-size-2);
  line-height: 21px;
  padding: 24px 20px;
}

.switchLogo {
  width: 36%;
  height: auto;
  padding-top: 26px;
  padding-bottom: 6px;
}

.tooltipText {
  padding-bottom: 20px;
}

.dialogCtaButton {
  width: 100%;
  margin: auto;
  height: 42px;
  max-width: 168px;
  border-radius: 42px;
  margin-bottom: 8px;
  padding: 0 40px;
}
